<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" sm="4">
            <v-card outlined :loading="loadingProducts">
              <v-card-title> Products </v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item v-if="products.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No product Exceptions</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="(product, index) in products"
                    :key="index"
                    @click="addProduct(product, index)"
                  >
                  <v-list-item-action :key="productKey">
                      <v-icon v-if="product.resolved" small color="success"
                        >check_circle_outline</v-icon
                      >
                      <v-icon v-else color="orange" small>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.commodity }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ product.count }} affected records
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined :loading="loadingVarieties">
              <v-card-title> Varieties </v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item v-if="varieties.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Variety Exceptions</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="(variety, index) in varieties"
                    :key="index"  @click="addVariety(variety, index)"
                  >
                  <v-list-item-action :key="varietyKey">
                      <v-icon v-if="variety.resolved" small color="success"
                        >check_circle_outline</v-icon
                      >
                      <v-icon v-else color="orange" small>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ variety.commodity }} - {{ variety.variety }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ variety.count }} affected records
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined :loading="loadingLocations">
              <v-card-title> Locations </v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item v-if="locations.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Location Exceptions</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="(location, index) in locations"
                    :key="index"
                    @click="
                      !location.resolved
                        ? addPoi(location)
                        : poiResolved(location)
                    "
                  >
                    <v-list-item-action :key="locationKey">
                      <v-icon v-if="location.resolved" small color="success"
                        >check_circle_outline</v-icon
                      >
                      <v-icon v-else color="orange" small>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ location.locationCode }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ location.count }} affected records
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addPOIModal" width="60vw" persistent>
      <v-card height="80vh" :loading="poiSearchLoading">
        <v-toolbar flat dense>
          <v-tabs v-model="tab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab>Search Existing POI</v-tab>
          <v-tab>Create New POI</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-btn text @click="addPOIModal=false,poi={}">X</v-btn>
        </v-toolbar>
       
        <v-card-text>
          <div v-if="tab == 0" class="mt-2">
            <v-text-field
              hide-details
              outlined
              dense
              rounded
              clearable
              v-model="poiSearch"
              placeholder="Search"
              prepend-inner-icon="search"
              style="max-width: 400px"
            ></v-text-field>
            <v-list dense style="max-height: 70vh; overflow-y: auto">
              <v-list-item
                v-for="point in pointsOfInterest"
                :key="point.id"
                @click="confirmSelectedPoint(point)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ point.name }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="font-size: 12px"
                    class="text-wrap"
                  >
                    {{ point.address }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    v-if="point.poiFunctions && point.poiFunctions.length > 0"
                  >
                    <v-chip
                      class="mx-1"
                      x-small
                      v-for="functionality in point.poiFunctions"
                      :key="functionality.id"
                      >{{ functionality.functionality }}</v-chip
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="pointsOfInterest.length == 0">
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey"
                    >No points found.</span
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div v-if="tab == 1" style="height: 70vh">
            <PoiQuickAdd
              :key="refreshKey"
              :selectedLocationCode="poi.locationCode"
              @poiCreated="updateAffectedPOI"
              @close="(addPOIModal = false), (poi = {})"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSelectedPointModal" width="400px" persistent>
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="confirmSelectedPointModal = false">X</v-btn> -->
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like attach this POI to {{ poi.count }} stock
            records?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmSelectedPointModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="updateAffectedPOI(poi)"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="3000" color="blue" top>
      <v-icon small left>check_circle_outline</v-icon>
      {{ snackbarText }}</v-snackbar
    >

    <v-dialog
      v-model="hsCodeSearchModal"
      persistent
      width="1400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <HSCodeModal @close="hsCodeSearchModal = false" @select="applyHSCode" />
    </v-dialog>

    <v-dialog
      v-model="productModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ productType }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(productModal = false), (product = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-tabs v-model="productTab">
            <v-tabs-slider cols="primary"></v-tabs-slider>
            <v-tab>Existing {{ productType }}</v-tab>
            <v-tab>Create New {{ productType }}</v-tab>
          </v-tabs>
          <div v-if="productTab==0" class="mt-2">
            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchProduct" outlined dense clearable></v-text-field>
            <v-list dense v-if="productType == 'Product'" style="max-height: 60vh; overflow-y: auto">
              <v-list-item v-if="filteredProducts.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No products found</span>
              </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="prod in filteredProducts" :key="prod.id" @click="setProduct(prod)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ prod.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="prod.parentProduct">
                 Parent: {{ prod.parentProduct.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list dense v-else style="max-height: 60vh; overflow-y: auto">
              <v-list-item v-if="filteredVarieties.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No products found</span>
              </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="prod in filteredVarieties" :key="prod.id" @click="setVariety(prod)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ prod.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="prod.parentProduct">
                 Parent: {{ prod.parentProduct.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="productTab==1" class="mt-2">
            <v-row>
            <v-col cols="12">
              <v-autocomplete
              v-if="productType == 'Product'"
                label="Parent Item"
                outlined
                hide-no-data
                dense
                v-model="product.parentProduct"
                :menu-props="{ closeOnContentClick: true }"
                :items="allProducts.data.filter(x=>x.type == 'Product Group')"
                item-text="name"
                return-object
                item-value="id"
                clearable
              >
                <template v-slot:selection="{ attr, on, item }">
                  <span v-on="on" v-bind="attr">{{ item.name }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete> 
              <v-autocomplete
              v-else-if="productType == 'Variety'"
                label="Parent Item"
                outlined
                hide-no-data
                dense
                v-model="product.parentProduct"
                :menu-props="{ closeOnContentClick: true }"
                :items="allProducts.data.filter(x=>x.type == 'Product')"
                item-text="name"
                return-object
                item-value="id"
                clearable
              >
                <template v-slot:selection="{ attr, on, item }">
                  <span v-on="on" v-bind="attr">{{ item.name }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="product.name"
                label="Name*"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="product.botanicalName"
                label="Botanical Name"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="product.code"
                label="Code"
                disabled
                outlined
                dense
              ></v-text-field>

              <v-select
                v-model="product.type"
                outlined
                dense
                label="Type*"
                :items="types"
              ></v-select>
              <v-textarea
                rows="3"
                v-model="product.description"
                label="Description"
                outlined
                dense
              ></v-textarea>
              <v-list-item @click="hsCodeSearchModal = true">
                <v-list-item-action>
                  <v-icon :color="!product.hsCode ? 'orange' : null"
                    >qr_code_2</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="product.hsCode">
                    {{ product.hsCode.alternateCode }}
                  </v-list-item-title>
                  <v-list-item-title v-else> No Code listed </v-list-item-title>
                  <v-list-item-subtitle> HS Code </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-checkbox label="Patent" v-model="product.patent"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    label="Trademark"
                    v-model="product.trademark"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn color="primary" text @click="confirmProductModal = true">Create Product</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          </div>
         
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="confirmProductModal" width="600px">
    
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="confirmSelectedPointModal = false">X</v-btn> -->
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like to update {{ product.count }} records with these settings?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmProductModal = false"
              >No</v-btn
            >
            <v-btn
          v-if="productType == 'Product'"
          color="success"
              class="mx-2"
              text
              small
            @click="saveProduct"
            :loading="savingProduct"
            >Save</v-btn
          >
          <v-btn
          v-else
          color="success"
              class="mx-2"
              text
              small
            @click="saveVariety"
            :loading="savingProduct"
            >Save</v-btn
          >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import HSCodeModal from "../SystemComponents/HSCodeManagement.vue";

import PoiQuickAdd from "../POI/PoiQuickAdd.vue";

export default {
  components: {
    HSCodeModal,
    PoiQuickAdd,
  },
  data: () => ({
    addPOIModal: false,
    allProducts: {
      data: [],
      total: 0,
    },
    confirmProductModal: false,
    confirmSelectedPointModal: false,
    products: [],
    varieties: [],
    hsCodeSearchModal: false,
    locations: [],
    locationKey: 100,
    loadingAllProducts: false,
    loadingUpdatePOI: false,
    loadingLocations: false,
    loadingProducts: false,
    loadingVarieties: false,
    poi: {},
    pointsOfInterest: [],
    poiParams: {
      limit: 50,
      offset: 0,
      search: null,
    },
    poiSearch: null,
    poiSearchLoading: false,
    poiSearchTimeout: null,
    poiRequest: null,
    product: {},
    productKey: 300,
    productModal: false,
    productTab: 0,
    productType: null,
    savingProduct: false,
    searchProduct: null,
    snackbar: false,
    snackbarText: null,
    refreshKey: 100,
    tab: 0,
    types: [
      "Product Group",
      "Product",
      "Variety"
    ],
    varietyKey: 400,
  }),
  watch: {
    poiSearch(val) {
      if (this.poiSearchLoading) {
        clearTimeout(this.poiSearchTimeout);
      }
      if (this.poiRequest) {
        this.poiRequest.abort();
      }
      this.poiParams.filter = [];
      this.poiParams.search = val;
      this.poiSearchLoading = true;
      this.poiSearchTimeout = setTimeout(async () => {
        this.poiRequest = new AbortController();
        const signal = this.poiRequest.signal;
        this.pointsOfInterest = await this.$API.getPois({
          params: this.poiParams,
          signal,
        });
        this.poiSearchLoading = false;
      }, 500);
    },
  },
  computed: {
    filteredProducts(){
      if(this.searchProduct){
        return this.allProducts.data.filter(x => x.type == "Product" && x.name.toLowerCase().includes(this.searchProduct.toLowerCase()))
      }else{
        return this.allProducts.data.filter(x => x.type == "Product")
      }
    },
    filteredVarieties(){
      if(this.searchProduct){
        return this.allProducts.data.filter(x =>x.productId == this.product.productId && x.type == "Variety" && x.name.toLowerCase().includes(this.searchProduct.toLowerCase()))
      }else{
        return this.allProducts.data.filter(x => x.productId == this.product.productId && x.type == "Variety")
      }
    }
  },
  created() {
    this.loadProducts();
    this.getProductExceptions();
    this.getVarietyExceptions();
    this.getLocationExceptions();
  },
  methods: {
    addPoi(item) {
      this.poi = {
        locationCode: item.locationCode,
        count: item.count,
      };
      this.addPOIModal = true;
      this.refreshKey++;
    },
    addProduct(product, index) {
      // console.log(product)
      this.product = {code: product.commodity, initialCode: product.commodity, index, type: 'Product', count: product.count};
      // this.product.index = index;
      this.productType = 'Product'
      this.productModal = true;
    },
    addVariety(product, index) {
      // console.log(product)
      this.product = {code: product.variety, initialCode: product.variety, index, type: 'Variety', count: product.count, productId: product.productId};
      // this.product.index = index;
      let findProduct = this.allProducts.data.find(x => x.id == product.productId)
      this.product.parentProduct = findProduct
      this.product.parentProductId = findProduct.id
      this.product.originalProductId = findProduct.id
      this.product.product = findProduct
      console.log(this.product)
      this.productType = 'Variety'
      this.productModal = true;
    },
    applyHSCode(item) {
      this.product.hsCode = item;
      this.product.hsCodeId = item.id;
      this.hsCodeSearchModal = false;
    },
    confirmSelectedPoint(point) {
      this.poi = { ...point, ...this.poi };
      this.confirmSelectedPointModal = true;
    },
    async getProductExceptions() {
      this.loadingProducts = true;
      this.products = await this.$API.getExceptions({
        params: { type: "product" },
      });
      this.loadingProducts = false;
    },
    async getVarietyExceptions() {
      this.loadingVarieties = true;
      this.varieties = await this.$API.getExceptions({
        params: { type: "variety" },
      });
      this.loadingVarieties = false;
    },
    async getLocationExceptions() {
      this.loadingLocations = true;
      this.locations = await this.$API.getExceptions({
        params: { type: "locationCode" },
      });
      this.loadingLocations = false;
    },
    async loadProducts() {
      this.loadingAllProducts = true;
      this.allProducts = await this.$API.getProducts({});
      this.loadingAllProducts = false;
    },
    poiResolved(item) {
      this.snackbarText = item.locationCode + " already resolved!";
      this.snackbar = true;
    },
    async saveProduct() {
      this.savingProduct = true;
      if(this.product.id){
        await this.$API.updateAffectedProduct({
        productId: this.product.id,
        commodity: this.product.initialCode
      });
      } else {
        if (this.product.parentProduct) {
          this.product.parentProductId = this.product.parentProduct.id;
        } else {
          this.product.parentProductId = null;
        }
          let result = await this.$API.createProduct(this.product);
          this.product.id = result.id;
          await this.$API.updateAffectedProduct({
        productId: this.product.id,
        commodity: this.product.initialCode
      });
      }
      
      this.productModal = false;
      this.products[this.product.index].resolved = true;
      this.productKey++;
      this.product = {};
      this.productTab = 0
      this.savingProduct = false;
      this.confirmProductModal = false

    },
    async saveVariety() {
      this.savingProduct = true;
      if (this.product.parentProduct) {
        this.product.parentProductId = this.product.parentProduct.id;
        this.product.productId = this.product.parentProduct.id;
      } else {
        this.product.parentProductId = null;
      }
      if(this.product.id){
        await this.$API.updateAffectedVariety({
        productId: this.product.productId,
        originalProductId: this.product.originalProductId,
        varietyProductId: this.product.varietyProductId,
        variety: this.product.initialCode
      });
      } else {
     
        let result = await this.$API.createProduct(this.product);
        this.product.id = result.id;
        this.product.varietyProductId = result.id;
        await this.$API.updateAffectedVariety({
          productId: this.product.productId,
        originalProductId: this.product.originalProductId,
        varietyProductId: this.product.varietyProductId,
        variety: this.product.initialCode
      });
    }
      this.productModal = false;
      this.varieties[this.product.index].resolved = true;
      this.varietyKey++;
      this.product = {};
      this.productTab = 0
      this.savingProduct = false;
      this.confirmProductModal = false
    },
    setProduct(product){
      this.product = {...product, ...this.product}
      console.log(this.product)
      this.confirmProductModal = true
    },
    setVariety(product){
      console.log(product)
      this.product.id = product.id
      this.product.productId = product.productId
      this.product.varietyProductId = product.id
      console.log(this.product)
      this.confirmProductModal = true
    },
    async updateAffectedPOI(data) {
      this.confirmSelectedPointModal = false;
      this.loadingUpdatePOI = true;
      await this.$API.updateAffectedPOI({
        poiId: data.id,
        locationCode: this.poi.locationCode,
      });
      let index = this.locations.findIndex(
        (x) => x.locationCode == data.locationCode
      );
      this.locations[index].resolved = true;
      this.locationKey++;
      this.loadingUpdatePOI = false;
      this.addPOIModal = false;
      this.poi = {};
    },
  },
};
</script>